import { Button } from "antd";

type Props = {
  error: any;
  resetErrorBoundary: () => void;
};
const Fallback = ({ error, resetErrorBoundary }: Props) => {
  const onHandleTryAgain = () => {
    window.location.reload();
  };
  return (
    <div
      role="alert"
      className="flex flex-col items-center justify-center gap-3 w-full mt-10"
    >
      <p>Error:</p>
      <pre style={{ color: "red" }}>Something went wrong. please try again</pre>
      <Button onClick={onHandleTryAgain}>Try again</Button>
    </div>
  );
};

export default Fallback;
