import { createSlice } from "@reduxjs/toolkit";
import { IKoshKoshGameState } from "./state/IKoshKoshSocketState";

const initialState: IKoshKoshGameState = {
 games: [],
 timer: {
  timer: ""
 }
};

const koshKoshSocketSlice = createSlice({
  name: "koshKoshSocketSlice",
  initialState,
  reducers: {},
});

export default koshKoshSocketSlice.reducer;
