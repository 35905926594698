import { useAppSelector } from "../../../store/redux-hooks/redux-hooks";
import SpinnerWrapper from "./SpinnerWrapper";

const GenericSpinner = () => {
  const { currentApplication } = useAppSelector(
    (state) => state.AuthenticationSlice
  );
  return (
    <SpinnerWrapper>
      <div
        className={`spinner-event ${
          currentApplication !== 1 && "games-swiper"
        }`}
      >
        <div className="content">
          <div className="spinner" />
          <span className={currentApplication !== 1 ? "" : "title"}>
            {currentApplication !== 1 ? "Starting game..." : "Loading..."}
          </span>
        </div>
      </div>
    </SpinnerWrapper>
  );
};

export default GenericSpinner;
