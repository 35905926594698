import { configureStore } from "@reduxjs/toolkit";
import DialogSlice from "../slices/DialogSlice";
import ThemeSlice from "../slices/ThemeSlice";
import UserSlice from "../slices/UserSlice";
import AuthenticationSlice from "../slices/AuthenticationSlice";
import GameSlice from "../modules/LuckyNumber/slices/GameSlice";
import SocketSlice from "../modules/LuckyNumber/slices/SocketSlice";
import KoshKoshGameSlice from "../modules/KoshKosh/slices/KoshKoshGameSlice";
import KoshKoshSocketSlice from "../modules/KoshKosh/slices/KoshKoshSocketSlice";
import CommonSlice from "../common/slices/CommonSlice";
import AgentSlice from "../slices/AgentSlice";
const store = configureStore({
  reducer: {
    DialogSlice,
    ThemeSlice,
    UserSlice,
    AuthenticationSlice,
    GameSlice,
    SocketSlice,
    KoshKoshGameSlice,
    KoshKoshSocketSlice,
    CommonSlice,
    AgentSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
