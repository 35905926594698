import { IFormElement } from "../common/models/IFormElement";
import { IGame } from "../common/models/IGame";
import { ILanguage } from "../common/models/ILanguage.model";
import { ISocialMedia } from "../common/models/ISocialMedia";
import { IUser } from "../modules/HomePage/models/IUser";
import { INumberRange } from "../modules/LuckyNumber/models/INumberRanges";

export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL as string;

export const BASE_URL = process.env.REACT_APP_API_URL as string;
export const APP_SECRET = process.env.REACT_APP_SECRET_KEY as string;

export const GOOGLE_API_TOKEN = process.env
  .REACT_APP_GOOGLE_API_TOKEN as string;
export const GOOGLE_AUTH_API = process.env.REACT_APP_GOOGLE_AUTH_URL as string;
export const CURRENCY = "ETB";
export const TOTAL_WINNING_PRICE: number = 100000;

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const SELECTED_GAMES = "current_selected_game";
export const ACTIVE_GAME = "current_active_game";
export const SELECTED_LANGUAGE = "selected_language";
export const USER_ROLE = "user_role";

export const NUMBER_OF_PLAYERS = process.env.NUMBER_OF_PLAYERS as string;
export const GAME_MESSAGE = {
  STARTING_GAME: "starting game",
  STARTING_LOT: "starting lot",
};
export const INITIAL_LUCKY_NUMBER_STATE = {
  lucky_number: 0,
  total_collected_money_game: 0,
  number_of_players: 0,
  winners_amount: {
    first_winner_gains: 0,
    second_winner_gains: 0,
    third_winner_gains: 0,
  },
};

export enum Applications {
  Dashboard = 1,
  LuckyNumber,
  KoshKosh,
  Bingo,
  Agent,
}

export enum Roles {
  ADMIN = 1,
  PLAYER,
  AGENT,
  CASHIER,
}

export enum ShowErrorComponent {
  TRANSFER_MONEY,
  ERROR_MESSAGE,
}

export const USER_INITIAL_STATE: IUser = {
  id: 0,
  full_name: "",
  username: "",
  role: 0,
  deposit: {
    amount: 0,
  },
};

export const LOGIN_FORMS: IFormElement[] = [
  {
    name: "username",
    label: "Phone_or_email",
    placeholder: "Phone_or_email_address",
    type: "text",
    is_required: true,
    required_message: "phone_email_error_message",
  },
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
    is_required: true,
    required_message: "password_error_message",
  },
];

export const AGENTS_FORM: IFormElement[] = [
  {
    name: "full_name",
    label: "full_name",
    placeholder: "full_name",
    type: "text",
    is_required: true,
    required_message: "full_name_error",
  },
  {
    name: "userName",
    label: "Phone_or_email",
    placeholder: "Phone_or_email_address",
    type: "text",
    is_required: true,
    required_message: "phone_email_error_message",
  },
];

export const COMPANIES_FORM: IFormElement[] = [
  {
    name: "full_name",
    label: "full_name",
    placeholder: "full_name",
    type: "text",
    is_required: true,
    required_message: "full_name_error",
  },
  {
    name: "company_name",
    label: "company_name",
    placeholder: "company_name",
    type: "text",
    is_required: true,
    required_message: "company_name_error",
  },
  {
    name: "username",
    label: "Phone_or_email",
    placeholder: "Phone_or_email_address",
    type: "text",
    is_required: true,
    required_message: "phone_email_error_message",
  },
];

export const AGENT_PASSWORD: IFormElement[] = [
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
    is_required: true,
    required_message: "password_error_message",
  },
];

export const RESET_PASSWORD: IFormElement[] = [
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
    is_required: true,
    required_message: "password_error_message",
  },
  {
    name: "confirmPassword",
    label: "confirmPassword",
    placeholder: "confirmPassword",
    type: "password",
    is_required: true,
    required_message: "password_error_message",
  },
];

export const SIGNUP_FORMS: IFormElement[] = [
  {
    name: "full_name",
    label: "full_name",
    placeholder: "full_name",
    type: "text",
    is_required: true,
    required_message: "full_name_error",
  },
  {
    name: "username",
    label: "Phone_or_email",
    placeholder: "Phone_or_email_address",
    type: "text",
    is_required: true,
    required_message: "phone_email_error_message",
  },
  {
    name: "password",
    label: "Password",
    placeholder: "Password",
    type: "password",
    is_required: true,
    required_message: "password_error_message",
  },
];

export const TRANSFER_FORMS: IFormElement[] = [
  {
    name: "username",
    label: "Phone or email",
    placeholder: "Phone or email address",
    type: "text",
    is_required: true,
    required_message: "Please enter your phone number or email address",
  },
];

export const AGENT_FORMS: IFormElement[] = [
  {
    name: "full_name",
    label: "Full name",
    placeholder: "Full name",
    type: "text",
    is_required: true,
    required_message: "Please enter your name",
  },
  {
    name: "username",
    label: "Phone number or Email",
    placeholder: "Phone or email",
    type: "text",
    is_required: true,
    required_message: "Please enter your email or phone number",
  },
];

export const LOGIN_PAGE = {
  label: "Sign in",
  authenticationType: "login",
  socialLoginLabel: "Login by",
  linkLabel: "Create new account",
};

export const SIGNUP_PAGE = {
  label: "Sign up",
  authenticationType: "signup",
  socialLoginLabel: "Sign up by",
  linkLabel: "Login to your account",
};

export const DEFAULT_LUCKY_GAME: IGame = {
  id: 1,
  name: "10 Birr Game",
  amount: 10,
  gameName: 2,
};

export const LUCKY_GAMES: IGame[] = [
  {
    id: 1,
    name: "20 Birr Game",
    amount: 20,
    gameName: 2,
    totalEarn: 20000,
    totalPlayers: 1000,
  },
  {
    id: 2,
    name: "50 Birr Game",
    amount: 50,
    gameName: 2,
    totalEarn: 50000,
    totalPlayers: 1000,
  },
  {
    id: 3,
    name: "100 Birr Game",
    amount: 100,
    gameName: 2,
    totalEarn: 100000,
    totalPlayers: 1000,
  },
  {
    id: 4,
    name: "500 Birr Game",
    amount: 500,
    gameName: 2,
    totalEarn: 500000,
    totalPlayers: 1000,
  },
  {
    id: 5,
    name: "1000 Birr Game",
    amount: 1000,
    gameName: 2,
    totalEarn: 1000000,
    totalPlayers: 1000,
  },
];

export const DEFAULT_KOSH_KOSH_GAME: IGame = {
  id: 1,
  name: "20 Birr game",
  amount: 20,
  numberOfLot: 1,
  gameName: 3,
};

export const KOSH_KOSH_GAME: IGame[] = [
  {
    id: 1,
    name: "20 Birr game",
    amount: 20,
    numberOfLot: 1,
    gameName: 3,
    totalPlayers: 10,
  },
  {
    id: 2,
    name: "50 Birr game",
    amount: 50,
    numberOfLot: 1,
    gameName: 3,
    totalPlayers: 10,
  },
  {
    id: 3,
    name: "100 Birr game",
    amount: 100,
    numberOfLot: 5,
    gameName: 3,
    totalPlayers: 10,
  },
  {
    id: 4,
    name: "500 Birr game",
    amount: 500,
    numberOfLot: 5,
    gameName: 3,
    totalPlayers: 10,
  },
  {
    id: 5,
    name: "1000 Birr game",
    amount: 1000,
    numberOfLot: 5,
    gameName: 3,
    totalPlayers: 10,
  },
];

export const BINGO_GAMES: IGame[] = [
  {
    id: 1,
    name: "10 Birr game",
    amount: 10,
    numberOfLot: 1,
    gameName: 4,
  },
  {
    id: 2,
    name: "20 Birr game",
    amount: 20,
    numberOfLot: 1,
    gameName: 4,
  },
  {
    id: 3,
    name: "50 Birr game",
    amount: 50,
    numberOfLot: 1,
    gameName: 4,
  },
  {
    id: 4,
    name: "100 Birr game",
    amount: 100,
    numberOfLot: 5,
    gameName: 4,
  },
  {
    id: 5,
    name: "500 Birr game",
    amount: 500,
    numberOfLot: 5,
    gameName: 4,
  },
  {
    id: 6,
    name: "1000 Birr game",
    amount: 1000,
    numberOfLot: 5,
    gameName: 4,
  },
];

export const SOCIAL_MEDIAS: ISocialMedia[] = [
  {
    id: 1,
    name: "Facebook",
    icon: "ic:baseline-facebook",
    companyColor: "#3b5998",
  },
  {
    id: 2,
    name: "Telegram",
    icon: "ic:baseline-telegram",
    companyColor: "#229ED9",
  },
  {
    id: 3,
    name: "Twitter",
    icon: "pajamas:twitter",
    companyColor: "#55acee",
  },
  {
    id: 4,
    name: "LinkedIn",
    icon: "mdi:linkedin",
    companyColor: "#007bb6",
  },
  {
    id: 5,
    name: "others",
    icon: "material-symbols:share",
    companyColor: "#fda830",
  },
];

export const SUPPORTED_LANGUAGES: ILanguage[] = [
  {
    id: 1,
    name: "አማርኛ",
    code: "am",
  },
  {
    id: 2,
    name: "Afaan Oromo",
    code: "ao",
  },
  {
    id: 3,
    name: "ትግርኛ",
    code: "tr",
  },
  {
    id: 4,
    name: "English",
    code: "en",
  },
];

export const NUMBERS_RANGE: INumberRange[] = [
  {
    id: 1,
    numbers: "1-100",
  },
  {
    id: 2,
    numbers: "101-200",
  },
  {
    id: 3,
    numbers: "201-300",
  },
  {
    id: 4,
    numbers: "301-400",
  },
  {
    id: 5,
    numbers: "401-500",
  },
  {
    id: 6,
    numbers: "501-600",
  },
  {
    id: 7,
    numbers: "601-700",
  },
  {
    id: 8,
    numbers: "701-800",
  },
  {
    id: 9,
    numbers: "801-900",
  },
  {
    id: 10,
    numbers: "901-1000",
  },
];
