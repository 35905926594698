import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { frontend_routes } from "../../../utils/frontend_routes";
import { useIntl } from "react-intl";

const TransferMoney = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const onHandleTransferMoney = () => {
    navigate(frontend_routes.deposit);
  };
  return (
    <div className="flex flex-col items-start justify-center gap-4 w-full">
      <p>{intl.formatMessage({ id: "enough_money" })}</p>
      <div className="flex items-center justify-center w-full">
        <Button
          onClick={onHandleTransferMoney}
          size="middle"
          color="primary"
          type="primary"
          className="font-bold"
        >
          {intl.formatMessage({ id: "transfer_money" })}
        </Button>
      </div>
    </div>
  );
};

export default TransferMoney;
