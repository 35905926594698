import { createSlice } from "@reduxjs/toolkit";
import { IDialogState } from "./states/IDialogState";
import { Applications } from "../constants/constants";

const initialState: IDialogState = {
  accountDialog: {
    isOpen: false,
  },
  applicationDialog: {
    isOpen: false,
  },
  notificationDialog: {
    isOpen: false,
    title: "",
    errorComponent: 0,
    message: "",
    notificationType: "error",
  },
  modalState: {
    isOpen: false,
  },
  drawerDialog: {
    isOpen: false,
  },
  gamesDialog: {
    isOpen: false,
    gameType: Applications.LuckyNumber,
  },
};

const DialogSlice = createSlice({
  name: "dialogSlice",
  initialState,
  reducers: {
    openApplicationDialog: (state, action) => {
      state.applicationDialog.isOpen = action.payload;
    },
    openAccountDialog: (state, action) => {
      state.accountDialog.isOpen = action.payload;
    },
    openNotification: (state, action) => {
      state.notificationDialog = action.payload;
    },

    openModal: (state, action) => {
      state.modalState.isOpen = action.payload;
    },
    openDrawerDialog: (state, action) => {
      state.drawerDialog.isOpen = action.payload;
    },
    showGameDialog: (state, action) => {
      state.gamesDialog = action.payload;
    },
  },
});

export const {
  openApplicationDialog,
  openModal,
  openAccountDialog,
  openNotification,
  openDrawerDialog,
  showGameDialog,
} = DialogSlice.actions;

export default DialogSlice.reducer;
