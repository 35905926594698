export const frontend_routes = {
  home: "/",
  signIn: "/signIn",
  signUp: "/signUp",
  deposit: "/deposit",
  agentRegistration: "/agents",
  agentVerification: "/agentVerification",
  companyRegistration: "/companies",
  withdraws: "/withdraws",
  winners: "/winners",
  settings: "/settings",
  verification: "/accountVerification",
  forgotPassword: "/forgotPassword",
  resetPassword: "/resetPassword",
  notFound: "/notFound",
  support: "/support",
  demo: "/playDemo",

  branding: "/branding",
  offline: "/offline",

  gameScene: "/startGame/:lotId",
};
