export const backend_url = {
  signUp: "/users/signup",
  signIn: "/users/login",
  getAllCompanyGame: "/companyGames",
  me: "/users/me",
  refreshToken: "/users/refresh_token",
  verifyAccount: "/users/verifyAccount",
  forgotPassword: "/users/forgotPassword",
  forgotPasswordVerification: "/users/forgotPasswordVerification",
  resetPassword: "/users/resetPassword",

  deposit: "/transactions/deposit",
  addLuckyNumber: "/luckyNumber/addLuckyNumber",
  numberOfPlayersOnGame: "/luckyNumber/numberOfPlayersOnGame",
  currentPlayingGames: "/luckyNumber/currentPlayingGames",
  isGameOnShaking: "/luckyNumber/isGameOnShaking/",
  luckyNumberGameNumber: "/luckyNumber/gameNumber/",

  allCarts: "/koshkosh/allCarts",
  myCarts: "/koshkosh/myCarts/",
  gameCarts: "/koshkosh/gameCarts/",
  addCart: "/koshkosh/addCart",
  koshKoshGameNumber: "/koshkosh/gameNumber/",

  agentRegistration: "/users/agentRegistration",
  agentVerification: "/users/agentVerification",
  completeAgentRegistration: "/users/completeAgentRegistration",
};
