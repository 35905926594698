import { lazy, Suspense, useEffect } from "react";
import { notification, theme } from "antd";
import "./styles/main.scss";
import { BrowserRouter } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "./store/redux-hooks/redux-hooks";
import { themeData } from "./slices/ThemeSlice";
import GenericSpinner from "./common/components/GenericSwipper/GenericSpinner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { meQuery } from "./slices/UserSlice";
import { changeAuthenticationState } from "./slices/AuthenticationSlice";
import { Applications } from "./constants/constants";
import {
  getSelectedActiveGameFromLocalStorage,
  getSelectedLanguage,
  isUserJoinGame,
  logError,
  onResetError,
} from "./common/utils/utils";

import messages_en from "./locale/en.json";
import messages_am from "./locale/am.json";
import messages_ao from "./locale/ao.json";
import messages_tr from "./locale/tr.json";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { openNotification } from "./slices/DialogSlice";
import { Icon } from "@iconify/react";
import {
  changeActiveGame,
  updateIsLuckyNumberTakenMessage,
} from "./modules/LuckyNumber/slices/GameSlice";
import { IntlProvider } from "react-intl";
import { IGame } from "./common/models/IGame";
import SwiperCore, { Autoplay } from "swiper";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./common/components/ErrorBoundary/ErrorBoundary";
import AppErrorComponent from "./common/components/AppErrorComponent/AppErrorComponent";
import { changeLocale } from "./common/slices/CommonSlice";

const AsyncHomeRouterApp = lazy(() => import("./apps/HomeApp"));
const AsyncLuckyNumberApp = lazy(() => import("./apps/LuckyNumberApp"));
const AysncKoshKoshApp = lazy(() => import("./apps/KoshKoshApp"));
const AsyncBingoApp = lazy(() => import("./apps/BingoApp"));
const AsyncAgentApp = lazy(() => import("./apps/AgentApp"));
const loader = <GenericSpinner />;

const homeApp = (
  <div>
    <AsyncHomeRouterApp />
  </div>
);

const luckyNumber = (
  <div>
    <AsyncLuckyNumberApp />
  </div>
);

const koshKoshApp = (
  <div>
    <AysncKoshKoshApp />
  </div>
);
const bingoApp = (
  <div>
    <AsyncBingoApp />
  </div>
);

const agentApp = (
  <div>
    <AsyncAgentApp />
  </div>
);
const queryClient = new QueryClient();

function App() {
  SwiperCore.use([Autoplay]);

  const messages = {
    am: messages_am,
    ao: messages_ao,
    tr: messages_tr,
    en: messages_en,
  };
  const [api, contextHolder] = notification.useNotification();
  let router = null;
  const { isUserLoggedIn, currentApplication } = useAppSelector(
    (state) => state.AuthenticationSlice
  );
  const { isOpen, title, message, notificationType } = useAppSelector(
    (state) => state.DialogSlice.notificationDialog
  );
  const { selectedLanguage } = useAppSelector((state) => state.CommonSlice);

  const locale = selectedLanguage.code || "en";

  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(themeData(token));
    if (isUserLoggedIn) {
      dispatch(changeAuthenticationState(true));
    }
    const selectedActiveGame: IGame | null =
      getSelectedActiveGameFromLocalStorage();

    if (selectedActiveGame) {
      dispatch(changeActiveGame(selectedActiveGame));
    }

    const defaultLanguage = getSelectedLanguage();
    if (defaultLanguage) {
      dispatch(
        changeLocale({
          code: defaultLanguage.value,
          id: 1,
          name: defaultLanguage.label,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(meQuery());
    }
  }, [dispatch, isUserLoggedIn]);

  if (!isUserLoggedIn) {
    router = homeApp;
  } else {
    if (
      !isUserJoinGame(Applications.Dashboard) ||
      currentApplication === Applications.Dashboard
    ) {
      router = homeApp;
    }
    if (
      currentApplication === Applications.LuckyNumber ||
      isUserJoinGame(Applications.LuckyNumber)
    ) {
      router = luckyNumber;
    }

    if (
      currentApplication === Applications.KoshKosh ||
      isUserJoinGame(Applications.KoshKosh)
    ) {
      router = koshKoshApp;
    }
    if (
      currentApplication === Applications.Bingo ||
      isUserJoinGame(Applications.Bingo)
    ) {
      router = bingoApp;
    }
  }

  useEffect(() => {
    if (isOpen) {
      api[notificationType]({
        message: `${title}`,
        description: <AppErrorComponent />,
        duration: 5,
        placement: "top",
        closeIcon: (
          <Icon icon={"material-symbols:close"} color="white" fontSize={24} />
        ),
        onClose: () => {
          dispatch(
            openNotification({
              isOpen: false,
              title: "",
              message: "",
            })
          );
          dispatch(updateIsLuckyNumberTakenMessage(""));
        },
      });
    }
  }, [isOpen, message, notificationType]);

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onReset={onResetError}
      onError={logError}
    >
      <IntlProvider
        locale={locale}
        messages={messages[locale as keyof typeof messages]}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            {contextHolder}
            <Suspense fallback={loader}>{router}</Suspense>
          </BrowserRouter>
        </QueryClientProvider>
      </IntlProvider>
    </ErrorBoundary>
  );
}

export default App;
