import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAgentState } from "./states/IAgentState";
import AxiosService from "../common/services/https.service";
import { backend_url } from "../utils/backend_routes";
import { IAgent, ICompleteAgent } from "../modules/HomePage/models/IAgent";

const initialState: IAgentState = {
  agentRegistration: {
    status: false,
    message: "",
  },
  agentVerification: {
    status: false,
    message: "",
  },
  completeAgentRegistration: {
    status: false,
    message: "",
    access_token: "",
    refresh_token: "",
    role: 0,
  },
};

export const agentRegistrationAPI = createAsyncThunk(
  "api/agentRegistration",
  async (data: IAgent, thunkAPI) => {
    try {
      const response = await AxiosService().post(
        backend_url.agentRegistration,
        data
      );
      return response.data;
    } catch (error: any) {
      if (error) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue("Incorrect verification code is used");
      }
    }
  }
);

export const agentVerificationAPI = createAsyncThunk(
  "api/agentVerificationAPI",
  async (data: any, thunkAPI) => {
    try {
      const response = await AxiosService().post(
        backend_url.agentVerification,
        data
      );
      return response.data;
    } catch (error: any) {
      if (error) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue("Incorrect verification code is used");
      }
    }
  }
);

export const agentCompleteRegistration = createAsyncThunk(
  "api/agentCompletionAPI",
  async (data: ICompleteAgent, thunkAPI) => {
    try {
      const response = await AxiosService().post(
        backend_url.completeAgentRegistration,
        data
      );
      return response.data;
    } catch (error: any) {
      if (error) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue("Incorrect verification code is used");
      }
    }
  }
);

const agentSlice = createSlice({
  name: "agentSlice",
  initialState,
  reducers: {
    updateAgentRegistrationStatus: (state, action) => {
      state.agentRegistration = action.payload;
    },
    updateAgentVerificationStatus: (state, action) => {
      state.agentVerification = action.payload;
    },
    updateAgentCompletionStatus: (state, action) => {
      state.completeAgentRegistration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(agentRegistrationAPI.pending, (state: any) => {
        state.agentRegistration.status = false;
        state.agentRegistration.message = "";
      })
      .addCase(agentRegistrationAPI.fulfilled, (state: any, action: any) => {
        state.agentRegistration = { ...action.payload };
      })
      .addCase(agentRegistrationAPI.rejected, (state: any, action: any) => {
        state.agentRegistration.status = false;
        state.agentRegistration.message = "";
      })
      //agent verification
      .addCase(agentVerificationAPI.pending, (state: any) => {
        state.agentVerification.status = false;
        state.agentVerification.message = "";
      })
      .addCase(agentVerificationAPI.fulfilled, (state: any, action: any) => {
        state.agentVerification = { ...action.payload };
      })
      .addCase(agentVerificationAPI.rejected, (state: any, action: any) => {
        state.agentVerification.status = false;
        state.agentVerification.message = "Something is not good";
      })
      //agent verification
      .addCase(agentCompleteRegistration.pending, (state: any) => {
        state.completeAgentRegistration.status = false;
        state.completeAgentRegistration.message = "";
      })
      .addCase(
        agentCompleteRegistration.fulfilled,
        (state: any, action: any) => {
          state.completeAgentRegistration = { ...action.payload };
        }
      )
      .addCase(
        agentCompleteRegistration.rejected,
        (state: any, action: any) => {
          state.completeAgentRegistration.status = false;
          state.completeAgentRegistration.message = "Something is not good";
        }
      );
  },
});

export const {
  updateAgentCompletionStatus,
  updateAgentRegistrationStatus,
  updateAgentVerificationStatus,
} = agentSlice.actions;

export default agentSlice.reducer;
