import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosService from "../../../common/services/https.service";
import { backend_url } from "../../../utils/backend_routes";
import { IKoshKoshGameState } from "./state/IKoshKoshGameState";
import { IPostCart } from "../model/IPostCart.model";
import { AxiosError } from "axios";

const initialState: IKoshKoshGameState = {
  carts: {
    status: false,
    loading: true,
    message: "",
    carts: [],
    error: false,
    errorMessage: "",
  },
  allPlayingGames: {
    selectedGames: [],
    activeGame: {
      id: 0,
      amount: 0,
      name: "",
      gameName: 0,
    },
  },
  myCarts: {
    loading: true,
    status: false,
    error: false,
    errorMessage: "",
    my_carts: [],
  },

  gameCarts: {
    loading: true,
    errorMessage: "",
    status: false,
    error: false,
    isLocked: false,
    winner_level: 1,
    carts: [],
    shaking_time: 0,
  },
  addCart: {
    status: false,
    message: "",
    loading: false,
    error: false,
  },
  gameNumber: {
    status: false,
    loading: true,
    game_number: "",
    error: false,
  },
  isShakingEnd: false,
  showGameWinners: false,
  shakingTimeAndWinnerLevel: {
    shaking_time: 0,
    winner_level: 1,
  },
  showKoshKoshConfetti: {
    isShow: false,
  },
};

export const allCarts = createAsyncThunk(
  "api/allCarts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(`${backend_url.allCarts}`);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const addCartAPI = createAsyncThunk(
  "api/addCart",
  async (data: IPostCart, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        `${backend_url.addCart}`,
        data
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

interface Arguments {
  gameTypeId: number;
}

export const getMyCarts = createAsyncThunk(
  "api/getMyCarts",
  async (args: Arguments, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.myCarts}${args.gameTypeId}`
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const getGameCarts = createAsyncThunk(
  "api/getGameCarts",
  async (args: Arguments, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.gameCarts}${args.gameTypeId}`
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const getKoshKoshGameNumber = createAsyncThunk(
  "api/getKoshKoshGameNumber",
  async (args: Arguments, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.koshKoshGameNumber}${args.gameTypeId}`
      );
      return response.data;
    } catch (err) {
      const error: any = err;
      rejectWithValue(error?.message);
    }
  }
);

const koshKoshGameSlice = createSlice({
  name: "koshKoshSlice",
  initialState,
  reducers: {
    updateMyCart: (state, action) => {
      const myCartsData = [...state.myCarts.my_carts, action.payload];
      state.myCarts = { ...state.myCarts, my_carts: myCartsData };
    },
    updateNumberOfPlayers: (state, action) => {
      const gameCarts = [...state.gameCarts.carts, action.payload];
      state.gameCarts = { ...state.gameCarts, carts: gameCarts };
    },
    updateKoshKoshGameShaking: (state, action) => {
      state.isShakingEnd = action.payload;
    },
    updateShowGameWinner: (state, action) => {
      state.showGameWinners = action.payload;
    },
    updateShakingTimeAndWinnerLevel: (state, action) => {
      state.shakingTimeAndWinnerLevel = action.payload;
    },

    updateGameCartsGameLockedState: (state, action) => {
      state.gameCarts = { ...state.gameCarts, isLocked: action.payload };
    },
    handleShowKoshKoshConfetti: (state, action) => {
      state.showKoshKoshConfetti.isShow = action.payload;
    },
  },
  extraReducers: (builder) => {
    //all carts
    builder
      .addCase(allCarts.pending, (state: any) => {
        state.carts.status = false;
        state.carts.loading = true;
        state.carts.error = false;
        state.carts.errorMessage = "";
        state.carts.carts = [];
      })
      .addCase(allCarts.fulfilled, (state: any, action: any) => {
        state.carts.carts = action.payload.carts;
        state.carts.loading = false;
        state.carts.status = true;
        state.carts.error = false;
        state.carts.errorMessage = "";
      })
      .addCase(allCarts.rejected, (state: any, action: any) => {
        state.carts.status = false;
        state.carts.loading = false;
        state.carts.error = true;
        state.carts.errorMessage = "something_is_not_good";
      })
      //add cart
      .addCase(addCartAPI.pending, (state: any) => {
        state.addCart.status = false;
        state.addCart.loading = true;
        state.addCart.error = false;
        state.addCart.message = "";
      })
      .addCase(addCartAPI.fulfilled, (state: any, action: any) => {
        state.addCart.loading = false;
        state.addCart.status = action.payload;
        state.addCart.error = false;
        state.addCart.message = action.message;
      })
      .addCase(addCartAPI.rejected, (state: any, action: any) => {
        state.carts.status = false;
        state.carts.loading = false;
        state.carts.error = true;
        state.carts.message = "something_is_not_good";
      })
      //my carts
      .addCase(getMyCarts.pending, (state: any) => {
        state.myCarts.status = false;
        state.myCarts.loading = true;
        state.myCarts.error = false;
        state.myCarts.my_carts = [];
        state.myCarts.loading = true;
      })
      .addCase(getMyCarts.fulfilled, (state: any, action: any) => {
        state.myCarts.my_carts = action.payload?.my_carts;
        state.myCarts.loading = false;
        state.myCarts.status = true;
        state.myCarts.error = false;
        state.myCarts.loading = false;
      })
      .addCase(getMyCarts.rejected, (state: any, action: any) => {
        state.myCarts.status = false;
        state.myCarts.loading = false;
        state.myCarts.error = true;
        state.myCarts.loading = false;
        state.myCarts.errorMessage = "something_is_not_good";
      })
      // game carts
      .addCase(getGameCarts.pending, (state: any) => {
        state.gameCarts.status = false;
        state.gameCarts.loading = true;
        state.gameCarts.error = false;
        state.gameCarts.carts = [];
      })
      .addCase(getGameCarts.fulfilled, (state: any, action: any) => {
        state.gameCarts.carts = action.payload.carts;
        state.gameCarts.isLocked = action.payload.isLocked;
        state.gameCarts.shaking_time = action.payload.shaking_time;
        state.gameCarts.winner_level = action.payload.winner_level;
        state.gameCarts.loading = false;
        state.gameCarts.status = true;
        state.gameCarts.error = false;
      })
      .addCase(getGameCarts.rejected, (state: any, action: any) => {
        state.gameCarts.status = false;
        state.gameCarts.loading = false;
        state.gameCarts.error = true;
        state.gameCarts.errorMessage = "something_is_not_good";
      })
      //game number
      .addCase(getKoshKoshGameNumber.pending, (state: any) => {
        state.gameNumber.status = false;
        state.gameNumber.loading = true;
        state.gameNumber.error = false;
        state.gameNumber.game_number = "";
      })
      .addCase(getKoshKoshGameNumber.fulfilled, (state: any, action: any) => {
        state.gameNumber.game_number = action.payload.game_number;
        state.gameNumber.loading = false;
        state.gameNumber.status = true;
        state.gameNumber.error = false;
      })
      .addCase(getKoshKoshGameNumber.rejected, (state: any, action: any) => {
        state.gameNumber.status = false;
        state.gameNumber.loading = false;
        state.gameNumber.error = true;
        state.gameNumber.errorMessage = "something_is_not_good";
      });
  },
});

export const {
  updateMyCart,
  updateNumberOfPlayers,
  updateKoshKoshGameShaking,
  updateShowGameWinner,
  updateShakingTimeAndWinnerLevel,
  updateGameCartsGameLockedState,
  handleShowKoshKoshConfetti,
} = koshKoshGameSlice.actions;

export default koshKoshGameSlice.reducer;
