import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IGameState } from "./states/IGameState";
import AxiosService from "../../../common/services/https.service";
import { backend_url } from "../../../utils/backend_routes";
import { AxiosError } from "axios";

const initialState: IGameState = {
  gamesNumber: [],
  allPlayingGames: {
    selectedGames: [],
    activeGame: {
      id: 0,
      amount: 0,
      name: "",
      gameName: 0,
    },
  },
  selectedGameInformation: {
    luckyNumber: {
      selected_lucky_number: 0,
      userId: 0,
      game_type_id: 0,
      all_lucky_numbers: [],
      total_collected_money_game: 0,
      number_of_players: 0,
      winners_amount: {
        first_winner_gains: 0,
        second_winner_gains: 0,
        third_winner_gains: 0,
      },
    },
    loading: false,
    status: false,
    error: false,
    errorMessage: "",
  },
  isLuckyNumberTaken: {
    status: false,
    loading: false,
    luckyNumberChecker: {
      status: false,
      message: "",
    },
  },

  winningUser: {
    isShow: false,
  },
  currentPlayingGames: {
    status: false,
    loading: true,
    allPlayingGames: [],
  },
  gameShaking: {
    loading: false,
    status: false,
    error: false,
    errorMessage: "",
    shaking: {
      isOnShakingTime: false,
      shakingTime: 0,
      gameLevel: 0,
      winners: [],
      gameStatus: {
        isTransferred: false,
      },
    },
  },
  endShake: {
    isShakingEnd: false,
  },
  selectedNumberRange: {
    id: 1,
    numbers: "1-100",
  },
  gameNumber: {
    status: false,
    loading: true,
    game_number: "",
  },
};

interface Arguments {
  game_type_id: number;
}

interface ILuckyNumber {
  game_type_id: number;
  lucky_number: number;
  platform_id: number;
}

export const isGameOnShaking = createAsyncThunk(
  "api/gameLocked",
  async (args: Arguments, { rejectWithValue }) => {
    try {
      const response = await AxiosService()
        .get(`${backend_url.isGameOnShaking}${args.game_type_id}`)
        .then((response) => {
          const requestStartingTime = response.headers["request-started-at"];
          const currentTime = new Date().getTime();

          const requestDelay = currentTime - requestStartingTime;
          console.log(
            `Request Time: ${requestDelay}, ${requestStartingTime}, ${currentTime}`
          );
          const totalShakingTime = response.data.shakingTime - requestDelay;
          return {
            ...response.data,
            shakingTime: totalShakingTime,
          };
        });
      return response;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const getSelectedGameInformation = createAsyncThunk(
  "api/numberOfPlayers",
  async (args: Arguments, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.numberOfPlayersOnGame}?game_type_id=${args.game_type_id}`
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const getCurrentPlayingGames = createAsyncThunk(
  "api/currentPlayingGames",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.currentPlayingGames}`
      );
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const isLuckyNumberTaken = createAsyncThunk(
  "api/isLuckyNumberTaken",
  async (data: ILuckyNumber, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(
        `${backend_url.addLuckyNumber}`,
        data
      );
      return response.data;
    } catch (err) {
      const error: any = err;
      rejectWithValue(error?.message);
    }
  }
);

export const getLuckyNumberGameNumber = createAsyncThunk(
  "api/luckyNumberGameNumber",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(
        `${backend_url.luckyNumberGameNumber}`
      );
      return response.data;
    } catch (err) {
      const error: any = err;
      rejectWithValue(error?.message);
    }
  }
);

const gameSlice = createSlice({
  name: "gameSlice",
  initialState,
  reducers: {
    addSelectedNumber: (state, action) => {
      state.gamesNumber = [...state.gamesNumber, action.payload];
    },

    onHandleShowWinnerName: (state, action) => {
      state.winningUser = action.payload;
    },
    addPlayingGames: (state, action) => {
      const isSelectedGameAddedPreviously =
        state.allPlayingGames.selectedGames.filter(
          (g) => g.id === action.payload.id
        ).length === 0;
      if (
        isSelectedGameAddedPreviously ||
        state.allPlayingGames.selectedGames.length <= 0
      ) {
        state.allPlayingGames.selectedGames = [
          ...state.allPlayingGames.selectedGames,
          action.payload,
        ];
      }
    },

    bullAddPlayingGames: (state, action) => {
      state.allPlayingGames.selectedGames = action.payload;
    },
    changeActiveGame: (state, action) => {
      state.allPlayingGames = {
        ...state.allPlayingGames,
        activeGame: action.payload,
      };
    },

    updateIsLuckyNumberTakenStatus: (state, action) => {
      state.isLuckyNumberTaken.luckyNumberChecker = {
        message: state.isLuckyNumberTaken.luckyNumberChecker.message,
        status: action.payload,
      };
    },

    updateIsLuckyNumberTakenMessage: (state, action) => {
      state.isLuckyNumberTaken.luckyNumberChecker = {
        ...state.isLuckyNumberTaken.luckyNumberChecker,
        message: action.payload,
      };
    },

    onEndShake: (state, action) => {
      state.endShake.isShakingEnd = action.payload;
    },
    changeSelectedRange: (state, action) => {
      state.selectedNumberRange = action.payload;
    },
    updateIsGameOnShakingState: (state, action) => {
      state.gameShaking = {
        loading: true,
        status: false,
        error: false,
        errorMessage: "",
        shaking: {
          gameLevel: 3,
          gameStatus: {
            isTransferred: false,
          },
          shakingTime: 0,
          isOnShakingTime: false,
          winners: [],
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSelectedGameInformation.pending, (state: any) => {
        state.selectedGameInformation.status = false;
        state.selectedGameInformation.loading = true;
      })
      .addCase(
        getSelectedGameInformation.fulfilled,
        (state: any, action: any) => {
          state.selectedGameInformation.luckyNumber = action.payload;
          state.selectedGameInformation.loading = false;
          state.selectedGameInformation.status = true;
        }
      )
      .addCase(
        getSelectedGameInformation.rejected,
        (state: any, action: any) => {
          state.selectedGameInformation.status = false;
          state.selectedGameInformation.loading = false;
          state.selectedGameInformation.error = true;
          state.selectedGameInformation.errorMessage = action.payload;
        }
      )
      // lucky number checker
      .addCase(isLuckyNumberTaken.pending, (state: any) => {
        state.isLuckyNumberTaken.status = false;
        state.isLuckyNumberTaken.loading = true;
      })
      .addCase(isLuckyNumberTaken.fulfilled, (state: any, action: any) => {
        state.isLuckyNumberTaken.luckyNumberChecker = action.payload;
        state.isLuckyNumberTaken.loading = false;
        state.isLuckyNumberTaken.status = true;
      })
      .addCase(isLuckyNumberTaken.rejected, (state: any, action: any) => {
        state.isLuckyNumberTaken.status = false;
        state.isLuckyNumberTaken.loading = false;
      })
      // current playing games
      .addCase(getCurrentPlayingGames.pending, (state: any) => {
        state.currentPlayingGames.status = false;
        state.currentPlayingGames.loading = true;
      })
      .addCase(getCurrentPlayingGames.fulfilled, (state: any, action: any) => {
        state.currentPlayingGames.allPlayingGames =
          action.payload.allPlayingGames;
        state.currentPlayingGames.loading = false;
        state.currentPlayingGames.status = true;
      })
      .addCase(getCurrentPlayingGames.rejected, (state: any, action: any) => {
        state.currentPlayingGames.status = false;
        state.currentPlayingGames.loading = false;
      })
      // check if game locked or not
      .addCase(isGameOnShaking.pending, (state: any) => {
        state.gameShaking.loading = true;
      })
      .addCase(isGameOnShaking.fulfilled, (state: any, action: any) => {
        state.gameShaking.loading = false;
        state.gameShaking.status = true;
        state.gameShaking.shaking = action.payload;
      })
      .addCase(isGameOnShaking.rejected, (state: any, action: any) => {
        state.gameShaking.loading = false;
      })
      //  game number
      .addCase(getLuckyNumberGameNumber.pending, (state: any) => {
        state.gameNumber.status = false;
        state.gameNumber.loading = true;
      })
      .addCase(
        getLuckyNumberGameNumber.fulfilled,
        (state: any, action: any) => {
          state.gameNumber.game_number = action.payload.game_number;
          state.gameNumber.loading = false;
          state.gameNumber.status = true;
        }
      )
      .addCase(getLuckyNumberGameNumber.rejected, (state: any, action: any) => {
        state.gameNumber.status = false;
        state.gameNumber.loading = false;
      });
  },
});

export const {
  addSelectedNumber,
  onHandleShowWinnerName,
  changeActiveGame,
  addPlayingGames,
  updateIsLuckyNumberTakenStatus,
  bullAddPlayingGames,
  updateIsLuckyNumberTakenMessage,
  onEndShake,
  changeSelectedRange,
  updateIsGameOnShakingState,
} = gameSlice.actions;
export default gameSlice.reducer;
