if (!Array.prototype.some) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.some = function (callback, thisArg) {
    if (this == null) {
      throw new TypeError("Array.prototype.some called on null or undefined");
    }

    if (typeof callback !== "function") {
      throw new TypeError(`${callback} is not a function`);
    }

    for (var i = 0; i < this.length; i++) {
      if (callback.call(thisArg, this[i], i, this)) {
        return true;
      }
    }

    return false;
  };
}

// Polyfill for Array.prototype.includes
if (!Array.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.includes = function (searchElement, fromIndex) {
    if (this == null) {
      throw new TypeError(
        "Array.prototype.includes called on null or undefined"
      );
    }

    var O = Object(this);
    var len = O.length >>> 0;

    if (len === 0) {
      return false;
    }

    var n = fromIndex || 0;

    while (n < len) {
      if (
        O[n] === searchElement ||
        (Number.isNaN(O[n]) && Number.isNaN(searchElement))
      ) {
        return true;
      }
      n++;
    }

    return false;
  };
}
