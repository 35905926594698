import { createSlice } from "@reduxjs/toolkit";
import { isUserLoggedIn } from "../common/services/TokenService";
import { Applications } from "../constants/constants";

const initialState = {
  isUserLoggedIn: isUserLoggedIn(),
  currentApplication: Applications.Dashboard,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    changeAuthenticationState: (state, action) => {
      state.isUserLoggedIn = action.payload;
    },
    changeApplication: (state, action) => {
      state.currentApplication = action.payload;
    },
  },
});

export const { changeAuthenticationState, changeApplication } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;
