import { createSlice } from "@reduxjs/toolkit";
import { ISocketState } from "./states/ISocketState";

const initialState: ISocketState = {
  timer: {
    timer: "",
  },
  lucky_number: {
    selected_lucky_number: 0,
    game_type_id: 0,
    userId: 0,
    number_of_players: 0,
    all_lucky_numbers: [],
    total_collected_money_game: 0,
    winners_amount: {
      first_winner_gains: 0,
      second_winner_gains: 0,
      third_winner_gains: 0,
    },
  },

  transferredGames: {
    transferred_games: [],
  },
  winners: {
    winners: [],
  },
  message: {
    message: "",
  },
  nextWinner: {
    nextWinnerLevel: 0,
    gameDelay: 0,
    winners: [],
    transferredGames: [],
  },
  winners_game: {
    winners_game: [],
  },

  isConnected: true,
  selectedCart: {
    KoshKoshGameId: 0,
    UserId: 0,
    CartId: 0,
  },
  koshKoshGameLotStarted: [],
  KoshKoshGamestartDrawing: [],
  koshKoshGameWinners: {
    cart_id: {
      abbreviation: "",
      id: 0,
      numbers: [],
      KoshKoshGameId: 0,
    },
    game_id: 0,
    winner_level: 0,
    winner_number: 0,
    winner_user: {
      id: 0,
      full_name: "",
      username: "",
      role: 0,
      deposit: {
        id: 0,
        amount: 0,
      },
    },
  },
  completedGames: [],
  lotFiveGameWinners: {
    game_id: 0,
    cart_id: {
      id: 0,
      abbreviation: "",
      numbers: [],
      KoshKoshGameId: 0,
    },
    winner_level: 0,
    winner_number: 0,
    winner_user: {
      id: 0,
      full_name: "",
      username: "",
      role: 0,
      deposit: {
        id: 0,
        amount: 0,
      },
    },
  },
  startNextWinner: [],
};

const socketSlice = createSlice({
  name: "socketSlice",
  initialState,
  reducers: {
    addTimer: (state, action) => {
      state.timer = action.payload;
    },

    addLuckyNumber: (state, action) => {
      state.lucky_number = action.payload.lucky_number;
    },

    addTransferredGames: (state, action) => {
      state.transferredGames = action.payload;
    },

    addWinner: (state, action) => {
      state.winners = action.payload;
    },

    addGameMessage: (state, action) => {
      state.message = action.payload;
    },

    updateUpNextWinner: (state, action) => {
      state.nextWinner = action.payload.nextWinner;
    },
    setIsConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    addWinnersOfGame: (state, action) => {
      state.winners_game = action.payload;
    },

    updateSelectedCart: (state, action) => {
      state.selectedCart = action.payload.selected_cart;
    },
    koshKoshGameLotStartMessage: (state, action) => {
      state.koshKoshGameLotStarted = [
        ...state.koshKoshGameLotStarted,
        action.payload.lotting_about_started,
      ];
    },
    startKoshKoshGameLotDrawing: (state, action) => {
      state.KoshKoshGamestartDrawing = [
        ...state.KoshKoshGamestartDrawing,
        action.payload.start_kosh_kosh_drawing,
      ];
    },
    updateKoshKoshGameWinner: (state, action) => {
      state.koshKoshGameWinners = action.payload.kosh_kosh_game_winner;
    },
    updateKoshKoshLotStartingMessage: (state, action) => {
      state.koshKoshGameLotStarted = action.payload;
    },
    updateCompletedGames: (state, action) => {
      state.completedGames = [
        ...state.completedGames,
        action.payload.completed_game,
      ];
    },
    updateCompletedGamesAndWinners: (state, action) => {
      const { id } = action.payload;
      //update completed games
      const completedGame = state.completedGames.filter(
        (game) => game.game_id !== id
      );
      state.completedGames = completedGame;

      state.koshKoshGameWinners = {
        cart_id: {
          abbreviation: "",
          id: 0,
          numbers: [],
          KoshKoshGameId: 0,
        },
        game_id: 0,
        winner_level: 0,
        winner_number: 0,
        winner_user: {
          id: 0,
          full_name: "",
          username: "",
          role: 0,
          deposit: {
            id: 0,
            amount: 0,
          },
        },
      };

      //current lot starter
      const lotStarters = state.koshKoshGameLotStarted.filter(
        (game) => game.kosh_kosh_game_id !== id
      );
      state.koshKoshGameLotStarted = lotStarters;

      //update start message of the game
      const startGameMessage = state.KoshKoshGamestartDrawing.filter(
        (game) => game.kosh_kosh_game_id !== id
      );
      state.KoshKoshGamestartDrawing = startGameMessage;
    },

    lotFiveGameWinners: (state, action) => {
      state.lotFiveGameWinners = action.payload.lot_five_game_winners;
    },
    startNextWinner: (state, action) => {
      state.startNextWinner = [
        ...state.startNextWinner,
        action.payload.start_next_winner,
      ];
    },
    updateLotFiveNextGameWinner: (state, action) => {
      const { id } = action.payload;
      const newStartNextWinner = state.startNextWinner.filter(
        (nextWinner) => nextWinner.game_id !== id
      );
      state.startNextWinner = newStartNextWinner;
    },
  },
});

export const {
  addTimer,
  addLuckyNumber,
  addTransferredGames,
  addWinner,
  addGameMessage,
  updateUpNextWinner,
  setIsConnected,
  addWinnersOfGame,
  updateSelectedCart,
  koshKoshGameLotStartMessage,
  startKoshKoshGameLotDrawing,
  updateKoshKoshGameWinner,
  updateKoshKoshLotStartingMessage,
  updateCompletedGames,
  updateCompletedGamesAndWinners,
  lotFiveGameWinners,
  startNextWinner,
  updateLotFiveNextGameWinner,
} = socketSlice.actions;

export default socketSlice.reducer;
