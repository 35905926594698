import { ShowErrorComponent } from "../../../constants/constants";
import { useAppSelector } from "../../../store/redux-hooks/redux-hooks";
import TransferMoney from "../TransferMoney/TransferMoney";

const AppErrorComponent = () => {
  const { errorComponent, message } = useAppSelector(
    (state) => state.DialogSlice.notificationDialog
  );
  if (errorComponent === ShowErrorComponent.ERROR_MESSAGE) {
    return <span>{message}</span>;
  }

  if (errorComponent === ShowErrorComponent.TRANSFER_MONEY) {
    return <TransferMoney />;
  }
  return <div>Something went wrong</div>;
};

export default AppErrorComponent;
