import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUserState } from "./states/IUserState";
import AxiosService from "../common/services/https.service";
import { backend_url } from "../utils/backend_routes";
import { IDeposit } from "../modules/HomePage/models/IDeposit";
import { AxiosError } from "axios";
import { IVerificationCode } from "../modules/HomePage/models/IVerificationCode";
import {
  IForgotPassword,
  IForgotPasswordVerificationFormData,
} from "../modules/HomePage/models/IForgotPassword";

const initialState: IUserState = {
  user: {
    id: 0,
    full_name: "",
    username: "",
    role: 0,
    deposit: {
      amount: 0,
    },
  },
  selectedPaymentAmount: {
    id: 0,
    amount: 0,
    type: "amount",
  },
  verifyAccount: {
    error: false,
    errorMessage: "",
    status: false,
    message: "",
    loading: true,
  },
  forgotPassword: {
    error: false,
    errorMessage: "",
    status: false,
    loading: true,
    message: "",
    phoneNumber: "",
  },
  checkPhoneNumber: {
    phoneNumber: "",
    isPhoneExist: false,
  },
  forgotPasswordVerification: {
    access_token: "",
    status: false,
    error: false,
    errorMessage: "",
  },
  loading: true,
  status: false,
  error: false,
  errorMessage: "",
  deposit: {
    status: false,
    error: false,
    errorMessage: "",
    code: 0,
  },
};

export const meQuery = createAsyncThunk(
  "api/me",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosService().get(backend_url.me);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const depositAPI = createAsyncThunk(
  "api/deposit",
  async (data: IDeposit, { rejectWithValue }) => {
    try {
      const response = await AxiosService().post(backend_url.deposit, data);
      return response.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.code === AxiosError.ERR_NETWORK) {
          return rejectWithValue(
            "Network error is created. Check your network"
          );
        }
      } else {
        const error: any = err;
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const accountVerificationAPI = createAsyncThunk(
  "api/accountVerification",
  async (data: IVerificationCode, thunkAPI) => {
    try {
      const response = await AxiosService().post(
        backend_url.verifyAccount,
        data
      );
      return response.data;
    } catch (error: any) {
      if (error) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue("Incorrect verification code is used");
      }
    }
  }
);

export const forgotPasswordAPI = createAsyncThunk(
  "api/forgotPasswordAPI",
  async (data: IForgotPassword, thunkAPI) => {
    try {
      const response = await AxiosService().post(
        backend_url.forgotPassword,
        data
      );
      return response.data;
    } catch (error: any) {
      if (error) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(
          "No one registered by this phone number"
        );
      }
    }
  }
);

export const forgotPasswordVerificationAPI = createAsyncThunk(
  "api/forgotPasswordVerificationAPI",
  async (data: IForgotPasswordVerificationFormData, thunkAPI) => {
    try {
      const response = await AxiosService().post(
        backend_url.forgotPasswordVerification,
        data
      );
      return response.data;
    } catch (error: any) {
      if (error) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue("Incorrect verification code is used");
      }
    }
  }
);

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },

    updateUserDeposit: (state, action) => {
      state.user = {
        ...state.user,
        deposit: { ...state.user.deposit, amount: action.payload },
      };
    },
    handleSelectedPaymentAmount: (state, action) => {
      state.selectedPaymentAmount = action.payload;
    },
    updateDepositStatus: (state, action) => {
      state.deposit = { ...state.deposit, status: action.payload };
    },
    updateCheckPhoneNumber: (state, action) => {
      state.checkPhoneNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(meQuery.pending, (state: any) => {
        state.status = false;
        state.loading = true;
        state.user = [];
      })
      .addCase(meQuery.fulfilled, (state: any, action: any) => {
        state.user = action.payload;
        state.loading = false;
        state.status = true;
      })
      .addCase(meQuery.rejected, (state: any, action: any) => {
        state.status = false;
        state.loading = false;
        state.error = true;
        state.errorMessage = action.payload;
      })
      //deposit API
      .addCase(depositAPI.pending, (state: any) => {
        state.deposit.status = true;
        state.deposit.error = false;
      })
      .addCase(depositAPI.fulfilled, (state: any, action: any) => {
        state.deposit = { ...action.payload };
      })
      .addCase(depositAPI.rejected, (state: any, action: any) => {
        state.deposit.status = false;
        state.deposit.error = true;
        state.deposit.errorMessage = action.payload.message;
      })
      //deposit API
      .addCase(accountVerificationAPI.pending, (state: any) => {
        state.verifyAccount.status = false;
        state.verifyAccount.error = false;
      })
      .addCase(accountVerificationAPI.fulfilled, (state: any, action: any) => {
        state.verifyAccount = { ...action.payload };
      })
      .addCase(accountVerificationAPI.rejected, (state: any, action: any) => {
        state.verifyAccount.status = false;
        state.verifyAccount.error = true;
        state.verifyAccount.errorMessage = action.payload;
      })
      //forgot password
      .addCase(forgotPasswordAPI.pending, (state: any) => {
        state.forgotPassword.status = false;
        state.forgotPassword.error = false;
      })
      .addCase(forgotPasswordAPI.fulfilled, (state: any, action: any) => {
        state.forgotPassword.status = true;
        state.forgotPassword.message = action.payload.message;
        state.forgotPassword.phoneNumber = action.payload.phoneNumber;
      })
      .addCase(forgotPasswordAPI.rejected, (state: any, action: any) => {
        state.forgotPassword.status = false;
        state.forgotPassword.error = true;
        state.forgotPassword.errorMessage = action.payload;
      })
      //forgot password verification
      .addCase(forgotPasswordVerificationAPI.pending, (state: any) => {
        state.forgotPasswordVerification.status = false;
        state.forgotPasswordVerification.error = false;
      })
      .addCase(
        forgotPasswordVerificationAPI.fulfilled,
        (state: any, action: any) => {
          state.forgotPasswordVerification.status = true;
          state.forgotPasswordVerification.access_token =
            action.payload.access_token;
          state.forgotPasswordVerification.message = action.payload.message;
        }
      )
      .addCase(
        forgotPasswordVerificationAPI.rejected,
        (state: any, action: any) => {
          state.forgotPasswordVerification.status = false;
          state.forgotPasswordVerification.error = true;
          state.forgotPasswordVerification.errorMessage = action.payload;
        }
      );
  },
});

export const {
  addUser,
  updateUserDeposit,
  updateDepositStatus,
  handleSelectedPaymentAmount,
  updateCheckPhoneNumber,
} = userSlice.actions;
export default userSlice.reducer;
