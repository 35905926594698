import { createSlice } from "@reduxjs/toolkit";
import { ICommonState } from "./states/ICommonState";

const initialState: ICommonState = {
  selectedLanguage: {
    id: 0,
    code: "",
    name: "",
  },
  errorState: {
    isShow: false,
    message: "",
  },
  confetti: {
    isShow: false,
  },
  playAudio: {
    isPlay: false,
  },
  sideMenu: {
    selectedMenuId: 1,
  },
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    changeLocale: (state, action) => {
      state.selectedLanguage = action.payload;
    },

    setError: (state, action) => {
      state.errorState = action.payload;
    },
    showConfetti: (state, action) => {
      state.confetti.isShow = action.payload;
    },
    handlePlayGameAudio: (state, action) => {
      state.playAudio = action.payload;
    },
    changeSideMenu: (state, action) => {
      state.sideMenu.selectedMenuId = action.payload;
    },
  },
});

export const {
  changeLocale,
  setError,
  handlePlayGameAudio,
  showConfetti,
  changeSideMenu,
} = commonSlice.actions;

export default commonSlice.reducer;
