import {
  KOSH_KOSH_GAME,
  LUCKY_GAMES,
  ACTIVE_GAME,
  SELECTED_LANGUAGE,
  USER_ROLE,
} from "../../constants/constants";
import { IGame } from "../models/IGame";
import { getItemFromLocalStorage } from "../services/TokenService";

export const isUserJoinGame = (gameId: number) => {
  const selectedGame: IGame = JSON.parse(getItemFromLocalStorage(ACTIVE_GAME));

  if (!selectedGame) {
    return false;
  } else {
    return selectedGame.gameName === gameId;
  }
};

export const getLoggerUserRole = () => {
  return Number(localStorage.getItem(USER_ROLE));
};

export const getSelectedActiveGameFromLocalStorage = () => {
  const localItem = localStorage.getItem(ACTIVE_GAME);
  return typeof localItem === "string" ? JSON.parse(localItem) : null;
};

export const getCurrentGame = (gameName: number) => {
  if (gameName === 2) {
    return LUCKY_GAMES;
  } else if (gameName === 3) {
    return KOSH_KOSH_GAME;
  }
};

export const storeSelectedLanguage = (language: any) => {
  localStorage.setItem(SELECTED_LANGUAGE, JSON.stringify(language));
};

export const getSelectedLanguage = () => {
  const selectedLanguage = localStorage.getItem(SELECTED_LANGUAGE);
  return typeof selectedLanguage === "string"
    ? JSON.parse(selectedLanguage)
    : null;
};

export const getGameLanguageCode = (gameId: number) => {
  if (gameId === 1) {
    return "luckyNumber";
  } else if (gameId === 2) {
    return "koshKosh";
  }
};

export const getSub = (winnerLevel: number) => {
  if (winnerLevel === 1) {
    return "st";
  } else if (winnerLevel === 2) {
    return "nd";
  } else if (winnerLevel === 3) {
    return "rd";
  } else if (winnerLevel === 4) {
    return "th";
  } else if (winnerLevel === 5) {
    return "th";
  }
};

export const onResetError = () => {
  window.location.reload();
};

export const logError = (err: any) => {
  console.log(err);
};
